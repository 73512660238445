import { useTranslation } from 'react-i18next';

const useSearchResultsSEO = (action, area, property_type, bedroom, minprice, maxprice, propertyTypeVal) => {
 
    const { t } = useTranslation();

    let pagetitle = ''
    let pageh1 = ''
    let pagemetadesc = ''
    let introcopy = ''

    let myactin = t("for_sale")
    if(action == "lettings")
      myactin = t("to_rent")
    
    let myarea = area.charAt(0).toUpperCase() + area.substring(1).replaceAll('-', ' ')

    let myproperty = t("property")
    if(propertyTypeVal === "new_developments") {
        myproperty = t("new_homes")
    }
    if(property_type){
        myproperty = property_type.charAt(0).toUpperCase() + property_type.substring(1).replaceAll('-', ' ')+'s'
    }

    let mybedroom = ''
    if(bedroom > 0){
        mybedroom = ' '+t("bedrrom_seo_meta", {bedroom: bedroom})
    }

    let mypricestr = ''
    if (minprice > 0 && maxprice > 0) {
        mypricestr = ' '+t("btwprice_seo_meta", {minprice: currencyFormat(minprice), maxprice: currencyFormat(maxprice) });
    } else if (maxprice > 0) {
        mypricestr = ' '+t("maxprice_seo_meta", {maxprice: currencyFormat(maxprice) });
    } else if (minprice > 0) {
        mypricestr = ' '+t("minprice_seo_meta", {minprice: currencyFormat(minprice) });
    }
    
    pagetitle = `${myproperty} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mybedroom}${mypricestr} | ${process.env.GATSBY_SITE_NAME}`

    pageh1 = `${myproperty} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mybedroom}${mypricestr}`

    pagemetadesc = `${t("explore_seo_meta")} ${myactin} ${myarea ? t("in")+' '+myarea:''}. ${t("conact")} ${process.env.GATSBY_SITE_NAME} ${myarea ? t("in")+' '+myarea:''} ${t("to_find")}`

    if(area == ""){
        pagemetadesc = `${t("explore_seo_meta")} ${myactin}. ${t("conact")} ${process.env.GATSBY_SITE_NAME} ${t("to_find")}`
    }
    if(area != (process.env.GATSBY_DEFAULT_AREA.toLowerCase()) && area != ""){
        pagemetadesc = `${t("explore_range")} ${myactin} ${myarea ? t("in")+' '+myarea:''}. ${t("contact_our")} ${myactin} ${myarea ? t("in")+' '+myarea:''}.`
    }
    if(property_type){
        pagemetadesc = `${t("looking_for")} ${myproperty} ${myactin} ${myarea ? t("in")+' '+myarea:''}? ${t("prop_exports")} ${process.env.GATSBY_SITE_NAME} ${t("here_help")}`
    }
    if(bedroom > 0){
        pagemetadesc = `${t("explore_prop")} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mybedroom} ${t("here")}. ${t("contact_our_expert")} ${myarea ? t("in")+' '+myarea:''}.`
    }
    if(mypricestr){
        pagemetadesc = `${t("locate_our")} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mypricestr} ${t("with")} ${process.env.GATSBY_SITE_NAME}. ${t("book_appointment")} ${myactin}.`
    }
    if(mypricestr && myproperty != "Property"){
        pagemetadesc = `${t("check_out_the")} ${myproperty} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mypricestr} ${t("with")} ${process.env.GATSBY_SITE_NAME}.`
    }
    if(mypricestr && bedroom > 0){
        pagemetadesc = `${t("explore_list")} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mybedroom}${mypricestr} ${t("with")} ${process.env.GATSBY_SITE_NAME} ${t("and_request")}`
    }
    if(myproperty != "Property" && bedroom > 0){
        pagemetadesc = `${t("find_latest")} ${myproperty} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mybedroom}. ${t("conact")} ${process.env.GATSBY_SITE_NAME} ${t("and_lettings")} ${myactin}, ${t("to_arange")}`
    }
    if(myproperty != "Property" && bedroom > 0 && mypricestr && area != "london"){
        pagemetadesc = `${t("explore")} ${myproperty} ${myactin} ${myarea ? t("in")+' '+myarea:''}${mybedroom}${mypricestr} ${t("available")} ${t("with")} ${process.env.GATSBY_SITE_NAME}. ${t("contact_estate")} ${myarea ? t("in")+' '+myarea:''}.`
    }

    introcopy = `${t("explore_our")} ${pageh1.replace("Property", "property")}. ${t("for_more_info")} ${myactin} ${myarea ? t("in")+' '+myarea:''}, ${t("please_touch")} `
    
    return {
        pagetitle,
        pageh1,
        pagemetadesc,
        introcopy
    }
}
  
export default useSearchResultsSEO
  
const currencyFormat = (num, currency = '£', withSpace = false) => {
    var filterNum = filterNumber(num);
    if (!filterNum) {
        return `${currency} 0`
    }
    if (withSpace) {
        return `${currency} ${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
    } else {
        return `${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
    }

}

const filterNumber = (str) => {
    if (!str) return 0
    str = str.toString()
    return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

const numberFormat = num => {
    if (!num) return 0
    return new Intl.NumberFormat("en-US", {}).format(num)
}